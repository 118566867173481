import React, { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userService from '../../services/UserServices';
import { userActions } from '../../redux/slices/userSlice';
import { Box, TextField, Button, InputAdornment, Typography, IconButton } from '@mui/material';
import { dgv, formatValue } from './AddRUT';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo_vrt from './../../images/logo_vrt.png';

const UserLogin: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [RUT, setRUT] = useState('');
  const [RUTError, setRUTError] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const changeRUT = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    const checkDigit = dgv(+formattedValue.slice(0, -2));
    let validRut = '';
    if (checkDigit.toString() !== formattedValue.charAt(formattedValue.length - 1)) {
      validRut = 'El RUT entregado no es válido';
    }
    setRUT(formattedValue);
    setRUTError(validRut);
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const login = async () => {
    try {
      let response: any = null;
      response = await userService.loginUser({ rut: RUT, password });
      if (response) {
        const { user } = response;
        user.token = response.token;
        user.redirect = response.redirect;
        dispatch(userActions.login(user));
      } else {
        setLoginError('Contraseña y/o usuario incorrectos, intenta denuevo');
        setPassword('');
      }
    } catch (error) {
      setLoginError('Contraseña y/o usuario incorrectos, intenta denuevo');
      setPassword('');
    }
  };

  const recover = () => {
    navigate(`/recover_password`);
  };
  return (
    <Box sx={{ width: '100vw', display: 'inline-block', padding: '7%' }}>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo_vrt} width={'150px'} />
      </Box>
      <Box sx={{ marginBottom: '26px' }}>
        <Typography
          sx={{
            color: '#38383D',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '20px',
            marginBottom: '8px',
          }}>
          RUT
        </Typography>
        <TextField
          sx={{
            borderRadius: '4px',
            border: '1px solid #EBEBF0',
            background: '#FFF',
            width: '100%',
            height: '40px',
            color: '#A6A6B0',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            '&:focus-within .MuiInputAdornment-root .MuiSvgIcon-root': {
              color: '#004CDC',
            },
            '& .MuiInputBase-input::placeholder': {
              color: '#A6A6B0', // Placeholder color
              opacity: 1, // Ensure opacity is set to 1 for visibility
            },
            '& .MuiInputBase-input': {
              color: '#38383D',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon color={'inherit'} />
              </InputAdornment>
            ),
          }}
          placeholder="12345678-9"
          size="small"
          value={RUT}
          onChange={changeRUT}
          error={RUTError !== ''}
          helperText={RUTError}
        />
      </Box>
      <Box sx={{ marginBottom: '26px' }}>
        <Typography
          sx={{
            color: '#38383D',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '20px',
            marginBottom: '8px',
          }}>
          Contraseña
        </Typography>
        <TextField
          sx={{
            borderRadius: '4px',
            border: '1px solid #EBEBF0',
            background: '#FFF',
            width: '100%',
            height: '40px',
            color: '#A6A6B0',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            '&:focus-within .MuiInputAdornment-root .MuiSvgIcon-root': {
              color: '#004CDC',
            },
            '& .MuiInputBase-input::placeholder': {
              color: '#A6A6B0', // Placeholder color
              opacity: 1, // Ensure opacity is set to 1 for visibility
            },
            '& .MuiInputBase-input': {
              color: '#38383D',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color={loginError !== '' ? 'error' : 'inherit'} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={showPassword ? 'hide the password' : 'display the password'}
                  onClick={handleClickShowPassword}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          type={showPassword ? 'text' : 'password'}
          placeholder="Ingresar contraseña"
          autoComplete="current-password"
          value={password}
          onChange={changePassword}
          error={loginError !== ''}
        />
      </Box>
      <Box sx={{ borderTop: '1px solid #DDD', paddingTop: '12px' }}>
        <Button
          onClick={login}
          size="large"
          sx={{
            color: '#FFF',
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '550',
            lineHeight: '20px',
            textTransform: 'capitalize',
            borderRadius: '1000px',
            background: '#004CDC',
            width: '100%',
            padding: '12px 24px',
          }}
          variant="contained">
          Iniciar sesión
        </Button>
      </Box>
      <Box sx={{ marginTop: '26px' }}>
        <Button
          type="button"
          onClick={recover}
          size="large"
          sx={{
            width: '100%',
            color: loginError !== '' ? '#004CDC' : '#808089',
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            textTransform: 'none',
          }}
          variant="text">
          Recuperar contraseña
        </Button>
      </Box>
    </Box>
  );
};

export default UserLogin;
