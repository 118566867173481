import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';
import { getCurrentState } from './../redux/store';

const getAvailability = async (): Promise<any> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getCurrentState().user.token}` },
    method: 'get',
    url: `/reservations/availability`,
  });
  return response?.data;
};

const postReservation = async (start: string): Promise<any> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getCurrentState().user.token}` },
    method: 'post',
    url: `/reservations`,
    data: {
      start,
    },
  });
  return response?.data;
};

const deleteReservation = async (id: number): Promise<any> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getCurrentState().user.token}` },
    method: 'delete',
    url: `/reservations/${id}`,
  });
  return response?.data;
};

const updateReservation = async (id: number, start: string): Promise<any> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getCurrentState().user.token}` },
    method: 'put',
    url: `/reservations/${id}`,
    data: { start },
  });
  return response?.data;
};

const getReservations = async (): Promise<any> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getCurrentState().user.token}` },
    method: 'get',
    url: `/reservations`,
  });
  return response?.data;
};

const reservationServices = {
  getAvailability,
  postReservation,
  getReservations,
  deleteReservation,
  updateReservation,
};

export default reservationServices;
