import React, { FC, useEffect } from 'react';
import { Box, Fade } from '@mui/material';
import ReservationCreateButton from './ReservationCreateButton';
import ReservationsCard from './ReservationsCard';
import ReservationsDrawer from './ReservationsDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { reservationActions } from '../../redux/slices/reservationSlice';
import reservationServices from '../../services/ReservationServices';
import { RootState } from '../../redux/store';
import EditReservationDrawer from './EditReservationDrawer';

const ReservationsCarousel: FC = () => {
  const dispatch = useDispatch();
  const reservations = useSelector((state: RootState) => state.reservation.reservations);
  const hidden = useSelector((state: RootState) => state.reservation.menuHidden);
  const updateHidden = useSelector((state: RootState) => state.reservation.menuUpdateHidden);

  useEffect(() => {
    reservationServices.getReservations().then((res) => {
      const sorted = res.sort((a: any, b: any) => new Date(a.start).getTime() - new Date(b.start).getTime());
      dispatch(reservationActions.setReservations(sorted));
    });
  }, [hidden, updateHidden]);

  const handleCreateReservation = () => {
    dispatch(reservationActions.select());
  };
  const updateReservation = async (reservation: any) => {
    dispatch(reservationActions.selectReservation(reservation));
  };

  return (
    <Fade in>
      <Box
        alignItems="stretch"
        sx={{
          textAlign: 'left',
          margin: '0',
          padding: '0',
        }}>
        <Box sx={{ paddingX: '5vw' }}>
          <h2 className="App-logo-text">Tus reservas 🗓️</h2>
        </Box>
        <Box
          sx={{
            paddingY: '0',
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
            width: '100vw',
            overflow: 'scroll',
            padding: '0px 20px 20px 20px',
          }}>
          {reservations.map((reservation: any) => (
            <ReservationsCard
              key={reservation.id}
              onClick={() => updateReservation(reservation)}
              date={`${
                ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'][
                  new Date(`${reservation.start.split('T')[0].substring(0, 10)}T10:00:00`).getDay()
                ]
              } ${reservation.start.split('T')[0].substring(8, 10)} de ${
                [
                  'Enero',
                  'Febrero',
                  'Marzo',
                  'Abril',
                  'Mayo',
                  'Junio',
                  'Julio',
                  'Agosto',
                  'Septiembre',
                  'Octubre',
                  'Noviembre',
                  'Diciembre',
                ][parseInt(reservation.start.split('T')[0].substring(5, 7)) - 1]
              }`}
              time={reservation.start.split('T')[1].substring(0, 5)}
              duration="10"
            />
          ))}
          <ReservationCreateButton onClick={handleCreateReservation} disabled={reservations.length >= 2} />
        </Box>
        <ReservationsDrawer />
        <EditReservationDrawer />
      </Box>
    </Fade>
  );
};

export default ReservationsCarousel;
