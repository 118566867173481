import { ReservationState } from '../types/reservationTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action } from './base';

const initialState: ReservationState = {
  menuHidden: true,
  menuUpdateHidden: true,
  reservations: [],
};

const ReservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    select: (state) => {
      state.menuHidden = false;
      state.selectedReservation = undefined;
      state.menuUpdateHidden = true;
    },
    selectReservation: (state, action: Action) => {
      state.selectedReservation = action.payload;
      state.menuHidden = true;
      state.menuUpdateHidden = false;
    },
    unselect: (state) => {
      state.menuHidden = true;
      state.menuUpdateHidden = true;
    },
    setReservations: (state, action: Action) => {
      state.reservations = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const reservationActions = ReservationSlice.actions;
export const reservationReducer = ReservationSlice.reducer;
