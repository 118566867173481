import React, { FC } from 'react';
import Fade from '@mui/material/Fade';
import { Box, Typography } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';

interface Props {
  disabled?: boolean;
  onClick?: () => void;
}

const ReservationCreateButton: FC<Props> = (props: Props) => {
  return (
    <Fade in={true}>
      <Box
        onClick={props.disabled ? undefined : props.onClick}
        sx={{
          borderRadius: '19.2px',
          boxShadow: '0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.30)',
          width: '140.4px',
          height: '206.4px',
          background: props.disabled ? '#EDEDED' : '#004CDC',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '140.4px',
            height: '206.4px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            gap: '7.2px',
            flexShrink: '0',
          }}>
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Saira, sans-serif',
              fontSize: '22px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '21.6px',
              letterSpacing: '0.18px',
              display: 'flex',
              height: '42px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignSelf: 'stretch',
            }}>
            Agendar sesión
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '84.24px',
              padding: '18.72px 24.96px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15.6px',
              borderRadius: '63.96px',
              background: '#FFF',
            }}>
            <PlusIcon />
          </Box>
          <Typography
            sx={{
              color: '#B6C4FF',
              textAlign: 'center',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '13.2px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '19.2px',
              letterSpacing: '0.6px',
            }}>
            {props.disabled ? '' : 'Pincha aquí'}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};

export default ReservationCreateButton;
