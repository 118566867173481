import { Box, Button, Drawer, Fade, Grow, Skeleton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Card, CardHeader } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import React, { FC, Fragment, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import EditOutlineIcon from '@mui/icons-material/EditOutlined';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import reservationServices from '../../services/ReservationServices';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { reservationActions } from '../../redux/slices/reservationSlice';
import { ReactComponent as CalendarSvg } from '../../images/calendar.svg';

const addMinutesToTime = (time: string, minutes: number): string => {
  const [hours, mins] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + mins + minutes;
  const newHours = Math.floor(totalMinutes / 60) % 24; // Wrap around after 24 hours
  const newMinutes = totalMinutes % 60;
  return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
};

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  ...(theme.palette.mode === 'dark'
    ? {
        backgroundColor: grey[900],
      }
    : {}),
}));

const ReservationsDrawer: FC = () => {
  const [selectedDate, setSelectedDate] = useState('2024-11-28');
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [step, setStep] = useState(0);
  const [availability, setAvailability] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const hidden = useSelector((state: RootState) => state.reservation.menuHidden);
  const dispatch = useDispatch();

  useEffect(() => {
    setSuccess(false);
    reservationServices.getAvailability().then((res) => {
      setSelectedDate(Object.keys(res)[0]);
      setAvailability(res);
      setSelectedTime(null);
    });
  }, [hidden]);

  const toggleDrawer = () => {
    setStep(0);
    dispatch(reservationActions.unselect());
  };

  const handleDateChange = (event: React.MouseEvent<HTMLElement>, date: string) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleTimeChange = (event: React.MouseEvent<HTMLElement>, time: string) => {
    setSelectedTime(time);
  };

  const handleReservation = async () => {
    if (selectedDate !== null && selectedTime !== null) {
      try {
        const response = await reservationServices.postReservation(`${selectedDate} ${selectedTime}`);
        console.log(response);
        if (response.message === 'Reserva creada exitosamente') {
          setSuccess(true);
        }
      } catch (error) {
        setSuccess(false);
      }
      setStep(step + 1);
    }
  };

  return (
    <Drawer
      sx={{ zIndex: '10200', width: '100vw' }}
      PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
      anchor="bottom"
      open={hidden === false}
      onClose={toggleDrawer}>
      <Box
        sx={{
          position: 'absolute',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
          paddingBottom: '200px',
        }}>
        <Puller />
      </Box>
      <Card sx={{ paddingTop: '20px', width: '100vw', minHeight: '50vh', borderRadius: '25px 25px 0px 0px' }}>
        <SwipeableViews disabled index={step}>
          <Fragment key="1">
            <CardHeader
              action={
                <IconButton aria-label="settings" onClick={toggleDrawer}>
                  <CloseIcon />
                </IconButton>
              }
              title={
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '32px',
                    letterSpacing: '0px',
                    paddingLeft: '20px',
                  }}>
                  Agenda una sesión
                </Typography>
              }
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '20px',
              }}>
              <Typography
                sx={{
                  color: '#434655',
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '22px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '28px',
                  letterSpacing: '0px',
                  maxWidth: '354px',
                  width: '100%',
                  paddingLeft: '11px',
                }}>
                Fecha
              </Typography>
            </Box>
            {availability !== null && selectedDate !== null ? (
              <Box sx={{ maxWidth: '100%', overflowX: 'scroll', paddingY: '14px', paddingX: '11px' }}>
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  aria-label="Platform"
                  value={selectedDate}
                  onChange={handleDateChange}>
                  {Object.keys(availability).map((date) => (
                    <ToggleButton
                      key={date}
                      value={date}
                      sx={{
                        display: 'flex',
                        width: '72px',
                        padding: '6px 20px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '62px',
                        background: '#FFF',
                        '&.MuiToggleButtonGroup-grouped': {
                          borderRadius: '62px !important',
                          padding: '6px 20px',
                          width: '72px',
                          mx: 1,
                          border: 'none',
                          whiteSpace: 'nowrap',
                        },
                      }}>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontFamily: 'Roboto, sans-serif',
                          fontSize: '11px',
                          fontStyle: 'normal',
                          fontWeight: '550',
                          lineHeight: '16px',
                          letterSpacing: '0.5px',
                          textTransform: 'none',
                        }}>
                        {['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'][new Date(`${date}T10:00:00`).getDay()]}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontFamily: 'Roboto, sans-serif',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '20px',
                          letterSpacing: '0.1px',
                          textTransform: 'none',
                        }}>
                        {new Date(`${date}T10:00:00`).getDate()}{' '}
                        {
                          ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'][
                            new Date(`${date}T10:00:00`).getMonth()
                          ]
                        }
                      </Typography>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>
            ) : (
              <Box sx={{ maxWidth: '100%', overflowX: 'scroll', paddingY: '14px', paddingX: '11px' }}>
                <ToggleButtonGroup>
                  <Skeleton variant="rounded" width="353px" height="42px" />
                </ToggleButtonGroup>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '20px',
              }}>
              <Typography
                sx={{
                  color: '#434655',
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '22px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '28px',
                  letterSpacing: '0px',
                  paddingLeft: '11px',
                  maxWidth: '354px',
                  width: '100%',
                }}>
                Horario
              </Typography>
            </Box>
            {availability !== null && selectedDate !== null ? (
              <Box
                sx={{
                  maxWidth: '100%',
                  paddingTop: '14px',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '304px',
                  marginBottom: '0px',
                }}>
                <div className="scroll-box">
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    aria-label="Platform"
                    value={selectedTime}
                    onChange={handleTimeChange}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      gap: '11px',
                      justifyContent: 'center',
                      maxWidth: '354px',
                      alignItems: 'center',
                    }}>
                    {Object.keys(availability[selectedDate as keyof typeof availability]).map((d: string) => (
                      <ToggleButton
                        disabled={availability[selectedDate][d] === false}
                        key={d}
                        value={d}
                        sx={{
                          display: 'flex',
                          width: '107px',
                          padding: '6px 20px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexShrink: '0',
                          '&.MuiToggleButtonGroup-grouped': {
                            borderRadius: '62px !important',
                            padding: '6px 20px !important',
                            width: '107px',
                            border: 'none',
                            whiteSpace: 'nowrap',
                          },
                          '&.Mui-disabled': {
                            background: '#E9E9EBFF',
                          },
                        }}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0.1px',
                          }}>
                          {d}
                        </Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  maxWidth: '100%',
                  paddingTop: '14px',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '304px',
                  marginBottom: '0px',
                }}>
                <ToggleButtonGroup>
                  <Skeleton variant="rounded" width="353px" height="304px" />
                </ToggleButtonGroup>
              </Box>
            )}
            <Box sx={{ marginX: '24px' }}>
              <Box
                sx={{
                  borderTop: '1px solid #E0E0E0',
                  display: 'flex',
                  padding: '16px 0px 29px 0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}>
                <Button
                  disabled={selectedTime === null}
                  onClick={() => setStep(step + 1)}
                  variant="contained"
                  color="primary"
                  sx={{
                    display: 'flex',
                    height: '40px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    flex: '1 0 0',
                    borderRadius: '100px',
                    textAlign: 'center',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '550',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    textTransform: 'none',
                  }}>
                  Siguiente
                </Button>
              </Box>
            </Box>
          </Fragment>
          <Fragment key="2">
            <CardHeader
              action={
                <IconButton aria-label="settings" onClick={toggleDrawer}>
                  <CloseIcon />
                </IconButton>
              }
              title={
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '32px',
                    letterSpacing: '0px',
                    paddingLeft: '20px',
                    width: '100%',
                  }}>
                  <IconButton aria-label="delete" onClick={() => setStep(step - 1)}>
                    <ArrowBackIcon />
                  </IconButton>
                  Resumen
                </Typography>
              }
            />
            <Box sx={{ paddingX: '24px', display: 'flex', justifyContent: 'center', direction: 'column' }}>
              <CalendarSvg />
            </Box>
            <Box sx={{ marginX: '24px' }}>
              <Box sx={{ display: 'flex', width: 'fit' }}>
                <Box sx={{ alignContent: 'left', marginRight: '34px' }}>
                  <Typography
                    sx={{
                      color: '#434655',
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '16px',
                      letterSpacing: '0.4px',
                    }}>
                    Fecha
                  </Typography>
                  <Typography
                    sx={{
                      color: '#434655',
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: '32px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '44px',
                      letterSpacing: '0px',
                    }}>
                    {new Date(`${selectedDate}T10:00:00`).getDate()}{' '}
                    {
                      ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'][
                        new Date(`${selectedDate}T10:00:00`).getMonth()
                      ]
                    }
                  </Typography>
                </Box>
                <Box sx={{ alignContent: 'left' }}>
                  <Typography
                    sx={{
                      color: '#434655',
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '16px',
                      letterSpacing: '0.4px',
                    }}>
                    Hora
                  </Typography>
                  <Typography
                    sx={{
                      color: '#434655',
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: '32px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '44px',
                      letterSpacing: '0px',
                    }}>
                    {selectedTime !== null ? `${selectedTime} - ${addMinutesToTime(selectedTime, 10)}` : 'No Time'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ paddingX: '24px', position: 'absolute', bottom: '0', width: '100%' }}>
              <Box
                sx={{
                  borderTop: '1px solid #E0E0E0',
                  display: 'flex',
                  padding: '16px 0px 29px 0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}>
                <Button
                  disabled={selectedTime === null}
                  onClick={() => setStep(step - 1)}
                  variant="outlined"
                  color="primary"
                  startIcon={<EditOutlineIcon />}
                  sx={{
                    display: 'flex',
                    height: '40px',
                    gap: '8px',
                    flex: '1 0 0',
                    borderRadius: '100px',
                    textAlign: 'center',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '550',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    textTransform: 'none',
                    maxWidth: '136px',
                    padding: '12px 24px',
                  }}>
                  Editar
                </Button>
                <Button
                  disabled={selectedTime === null}
                  onClick={handleReservation}
                  variant="contained"
                  color="primary"
                  sx={{
                    display: 'flex',
                    height: '40px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    flex: '1 0 0',
                    borderRadius: '100px',
                    textAlign: 'center',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '550',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    textTransform: 'none',
                  }}>
                  Reservar
                </Button>
              </Box>
            </Box>
          </Fragment>
          <Fragment key="3">
            <CardHeader
              action={
                <IconButton aria-label="settings" onClick={toggleDrawer}>
                  <CloseIcon />
                </IconButton>
              }
              title={
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '32px',
                    letterSpacing: '0px',
                    paddingLeft: '20px',
                    width: '100%',
                  }}>
                  Resumen
                </Typography>
              }
            />
            {success === true ? (
              <Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '24px' }}>
                  <Grow appear in timeout={{ enter: 1000 }} style={{ transformOrigin: '50% 100% 0' }}>
                    <CheckCircleIcon sx={{ color: '#00C49F', fontSize: '148px' }} />
                  </Grow>
                </Box>
                <Box sx={{ paddingX: '28px' }}>
                  <Grow appear in timeout={{ enter: 2500 }} style={{ transformOrigin: '50% 100% 0' }}>
                    <Typography
                      sx={{
                        color: '#434655',
                        textAlign: 'center',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '36px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '36px',
                        letterSpacing: '0px',
                      }}>
                      ¡Genial!
                    </Typography>
                  </Grow>
                  <Fade appear in timeout={{ enter: 1500 }} style={{ transitionDelay: '1500ms' }}>
                    <Typography
                      sx={{
                        paddingTop: '16px',
                        color: '#434655',
                        textAlign: 'center',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                        letterSpacing: '0px',
                      }}>
                      Tu reserva ha sido confirmada
                    </Typography>
                  </Fade>
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '24px' }}>
                  <Grow appear in timeout={{ enter: 1000 }} style={{ transformOrigin: '50% 100% 0' }}>
                    <ErrorIcon sx={{ color: '#F44336', fontSize: '148px' }} />
                  </Grow>
                </Box>
                <Box sx={{ paddingX: '28px' }}>
                  <Grow appear in timeout={{ enter: 2500 }} style={{ transformOrigin: '50% 100% 0' }}>
                    <Typography
                      sx={{
                        color: '#434655',
                        textAlign: 'center',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '36px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '36px',
                        letterSpacing: '0px',
                      }}>
                      ¡Ups! Algo salió mal.
                    </Typography>
                  </Grow>
                  <Fade appear in timeout={{ enter: 1500 }} style={{ transitionDelay: '1500ms' }}>
                    <Typography
                      sx={{
                        paddingTop: '16px',
                        color: '#434655',
                        textAlign: 'center',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                        letterSpacing: '0px',
                      }}>
                      Intenta en otro horario!
                    </Typography>
                  </Fade>
                </Box>
              </Fragment>
            )}
            <Box sx={{ paddingX: '24px', position: 'absolute', bottom: '0', width: '100%' }}>
              <Box
                sx={{
                  borderTop: '1px solid #E0E0E0',
                  display: 'flex',
                  padding: '16px 0px 29px 0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}>
                <Button
                  onClick={toggleDrawer}
                  variant="contained"
                  color="primary"
                  sx={{
                    display: 'flex',
                    height: '40px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    flex: '1 0 0',
                    borderRadius: '100px',
                    textAlign: 'center',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '550',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    textTransform: 'none',
                  }}>
                  Finalizar
                </Button>
              </Box>
            </Box>
          </Fragment>
        </SwipeableViews>
      </Card>
    </Drawer>
  );
};

export default ReservationsDrawer;
