import React, { FC } from 'react';
import Fade from '@mui/material/Fade';
import { Box, Typography } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

interface Props {
  date: string;
  time: string;
  duration: string;
  onClick?: () => void;
}

const ReservationsCard: FC<Props> = (props: Props) => {
  return (
    <Fade in={true}>
      <Box
        onClick={props.onClick}
        sx={{
          borderRadius: '19.2px',
          boxShadow: '0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.30)',
          width: '140.4px',
          height: '206.4px',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '140.4px',
            height: '206.4px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '7.2px',
            flexShrink: '0',
          }}>
          <Typography
            sx={{
              fontFamily: 'Saira, sans-serif',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '28px',
              textTransform: 'uppercase',
              overflow: 'hidden',
              color: '#38383D',
              textAlign: 'center',
              textOverflow: 'ellipsis',
            }}>
            Reserva
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '118px',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}>
            <AccessTimeOutlinedIcon sx={{ color: '#41E3BD' }} />
            <Typography
              sx={{
                color: '#808089',
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '16px',
              }}>
              {props.date}
            </Typography>
          </Box>
          <Typography
            sx={{
              overflow: 'hidden',
              color: '#004CDC',
              textAlign: 'center',
              textOverflow: 'ellipsis',
              fontFamily: 'Saira, sans-serif',
              fontSize: '40px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '45px',
            }}>
            {props.time}
          </Typography>
          <Typography
            sx={{
              color: '#999',
              textAlign: 'center',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '13.2px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '19.2px',
              letterSpacing: '0.6px',
            }}>
            ({props.duration} min)
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};

export default ReservationsCard;
