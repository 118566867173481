import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import userService from '../services/UserServices';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../redux/slices/userSlice';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const ResetPassword: FC = () => {
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { phone, email, token } = useParams();

  useEffect(() => {
    dispatch(userActions.logout());
  }, []);

  const reset = async () => {
    try {
      const response = await userService.resetPassword({ email, phone }, token, password);
      if (response.status === 'ok') {
        navigate('/login');
      } else {
        setSubmitError('Algo ha salido mal, intenta de nuevo más tarde');
      }
    } catch (e) {
      setSubmitError('Algo ha salido mal, intenta de nuevo más tarde');
    }
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
    if (confirmation !== event.target.value) {
      setPasswordError('las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const changeConfirmation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmation(event.target.value);
    if (password !== event.target.value) {
      setPasswordError('las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const login = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <Button
        onClick={login}
        startIcon={<ArrowBackOutlinedIcon />}
        sx={{
          color: '#004CDC',
          textAlign: 'center',
          fontFamily: 'Roboto, sans-serif',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '550',
          lineHeight: '20px',
          textTransform: 'capitalize',
          padding: '12px',
          position: 'absolute',
        }}>
        Atrás
      </Button>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ marginTop: '36px', marginBottom: '24px' }}>
          <IconButton
            size="large"
            sx={{ color: '#004CDC', border: '1.5px solid #004CDC', width: '86px', height: '86px' }}>
            <LockOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
        <Typography
          sx={{
            color: '#38383D',
            textAlign: 'center',
            fontFamily: 'Saira, sans-serif',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '28px',
            textTransform: 'uppercase',
            marginBottom: '24px',
          }}>
          CREAR NUEVA CONTRASEÑA
        </Typography>
        <Box sx={{ width: '100%', paddingX: '24px' }}>
          <Box sx={{ marginBottom: '26px' }}>
            <Typography
              sx={{
                color: '#38383D',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px',
                marginBottom: '8px',
              }}>
              Contraseña
            </Typography>
            <TextField
              sx={{
                borderRadius: '4px',
                border: '1px solid #EBEBF0',
                background: '#FFF',
                width: '100%',
                height: '40px',
                color: '#A6A6B0',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
                '&:focus-within .MuiInputAdornment-root .MuiSvgIcon-root': {
                  color: '#004CDC',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: '#A6A6B0', // Placeholder color
                  opacity: 1, // Ensure opacity is set to 1 for visibility
                },
                '& .MuiInputBase-input': {
                  color: '#38383D',
                },
              }}
              size="small"
              type="password"
              placeholder="Ingresar contraseña"
              autoComplete="current-password"
              value={password}
              onChange={changePassword}
            />
          </Box>
          <Box sx={{ marginBottom: '26px' }}>
            <Typography
              sx={{
                color: '#38383D',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px',
                marginBottom: '8px',
              }}>
              Confirma contraseña
            </Typography>
            <TextField
              sx={{
                borderRadius: '4px',
                border: '1px solid #EBEBF0',
                background: '#FFF',
                width: '100%',
                height: '40px',
                color: '#A6A6B0',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
                '&:focus-within .MuiInputAdornment-root .MuiSvgIcon-root': {
                  color: '#004CDC',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: '#A6A6B0', // Placeholder color
                  opacity: 1, // Ensure opacity is set to 1 for visibility
                },
                '& .MuiInputBase-input': {
                  color: '#38383D',
                },
              }}
              size="small"
              type="password"
              placeholder="Ingresar contraseña"
              autoComplete="current-password"
              value={confirmation}
              onChange={changeConfirmation}
              error={passwordError !== ''}
              helperText={passwordError}
            />
          </Box>
          <Typography
            sx={{
              color: 'error.main',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '300',
              lineHeight: '16px',
              marginBottom: '16px',
            }}>
            {submitError}
          </Typography>
          <Box
            sx={{
              paddingY: '12px',
              borderTop: '1px solid #C7C6CD',
              marginBottom: '64px',
              position: 'fixed',
              bottom: '0',
              width: 'calc(100% - 48px)',
            }}>
            <Button
              onClick={reset}
              size="large"
              sx={{
                borderRadius: '1000px',
                background: '#004CDC',
                color: '#FFF',
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '550',
                lineHeight: '20px',
                textTransform: 'capitalize',
                width: '100%',
                display: 'flex',
                padding: '12px 24px',
                alignItems: 'center',
                gap: '12px',
                flex: '1 0 0',
              }}
              variant="contained"
              disabled={password === '' || confirmation !== password || passwordError !== ''}>
              Aceptar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
