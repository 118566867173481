import React, { FC, useEffect, useState, ChangeEvent, Fragment } from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import { ReactComponent as WhatsAppIcon } from '../images/whatsapp_icon.svg';
import userService from '../services/UserServices';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';

const RecoverPassword: FC = () => {
  const [phone, setPhone] = useState<string | undefined>('569');
  const [phoneError, setPhoneError] = useState('');
  const [momentarilyDisabled, setMomentarilyDisabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);

  useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch(userActions.logout());
  }, []);

  const recover = () => {
    userService.recoverPassword(phone);
    setMomentarilyDisabled(true);
    setTimeout(() => {
      setMomentarilyDisabled(false);
    }, 30000);
    setStep(1);
  };

  const changePhone = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || (regex.test(event.target.value) && event.target.value.length <= 11)) {
      setPhone(event.target.value);
      const response = await userService.phoneIsRegistered(event.target.value);
      if (event.target.value.length === 11 && response.user === null) {
        setPhoneError('Número no registrado');
      } else if (event.target.value.length === 11 && response.user !== null) {
        setPhoneError('');
      } else {
        setPhoneError('Debe tener 11 digitos');
      }
    } else if (event.target.value.length <= 10) {
      setPhoneError('Debe tener 11 digitos');
    } else if (event.target.value.length !== 12) {
      setPhoneError('Error al ingresar el número');
    }
  };

  const login = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <SwipeableViews index={step}>
        <Fragment>
          <Box>
            <Button
              onClick={login}
              startIcon={<ArrowBackOutlinedIcon />}
              sx={{
                color: '#004CDC',
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '550',
                lineHeight: '20px',
                textTransform: 'capitalize',
                padding: '12px',
                position: 'absolute',
                top: '0px',
                left: '0px',
              }}>
              Atrás
            </Button>
          </Box>
          <Box
            sx={{
              height: '100vh',
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Box sx={{ marginTop: '36px', marginBottom: '24px' }}>
              <IconButton
                size="large"
                sx={{ color: '#004CDC', border: '1.5px solid #004CDC', width: '86px', height: '86px' }}>
                <VpnKeyOutlinedIcon fontSize="large" />
              </IconButton>
            </Box>
            <Typography
              sx={{
                color: '#38383D',
                textAlign: 'center',
                fontFamily: 'Saira, sans-serif',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '28px',
                textTransform: 'uppercase',
                marginBottom: '24px',
              }}>
              Recuperar contraseña
            </Typography>
            <Box sx={{ width: '100%', paddingX: '16px', marginBottom: '24px' }}>
              <Typography
                sx={{
                  color: '#38383D',
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '20px',
                  marginBottom: '8px',
                  width: '100%',
                  textAlign: 'left',
                }}>
                Teléfono
              </Typography>
              <Box sx={{ width: '100%' }}>
                <TextField
                  sx={{ width: '100%' }}
                  size="small"
                  variant="outlined"
                  value={phone}
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={changePhone}
                  error={phoneError !== ''}
                  helperText={phoneError}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', paddingX: '16px' }}>
              <Button
                disabled={phoneError !== '' || phone === '569'}
                onClick={recover}
                size="large"
                sx={{
                  borderRadius: '1000px',
                  background: '#004CDC',
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '550',
                  lineHeight: '20px',
                  textTransform: 'capitalize',
                  width: '100%',
                  display: 'flex',
                  padding: '12px 24px',
                  alignItems: 'center',
                  gap: '12px',
                  flex: '1 0 0',
                }}
                variant="contained">
                Enviar link
              </Button>
            </Box>
            <Box sx={{ width: '100%', marginTop: '12px' }}>
              <Button
                startIcon={<SupportAgentOutlinedIcon />}
                size="large"
                sx={{
                  borderRadius: '1000px',
                  background: '#FFF',
                  color: '#004CDC',
                  textAlign: 'center',
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '20px',
                  textTransform: 'capitalize',
                  width: '100%',
                  display: 'flex',
                  padding: '12px 24px',
                  alignItems: 'center',
                  gap: '12px',
                  flex: '1 0 0',
                  border: 'none',
                }}
                href="https://wa.me/56931107304">
                Contacta al soporte técnico
              </Button>
            </Box>
          </Box>
        </Fragment>
        <Fragment>
          <Button
            onClick={() => setStep(0)}
            startIcon={<ArrowBackOutlinedIcon />}
            sx={{
              color: '#004CDC',
              textAlign: 'center',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '550',
              lineHeight: '20px',
              textTransform: 'capitalize',
              padding: '12px',
              position: 'absolute',
            }}>
            Atrás
          </Button>
          <Box
            sx={{
              display: 'flex',
              height: '100vh',
              flexDirection: 'column',
              paddingTop: '240px',
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <WhatsAppIcon />
              <Typography
                sx={{
                  marginTop: '24px',
                  color: '#38383D',
                  textAlign: 'center',
                  fontFamily: 'Saira, sans-serif',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '28px',
                  textTransform: 'uppercase',
                }}>
                Revisa tu whatsapp
              </Typography>
              <Typography
                sx={{
                  color: '#808089',
                  textAlign: 'center',
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '20px',
                }}>
                y sigue las instrucciones del mensaje
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%', paddingX: '16px', position: 'absolute', bottom: '68px' }}>
            <Button
              disabled={momentarilyDisabled}
              onClick={recover}
              size="large"
              sx={{
                borderRadius: '1000px',
                background: '#004CDC',
                color: '#FFF',
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '550',
                lineHeight: '20px',
                textTransform: 'capitalize',
                width: '100%',
                display: 'flex',
                padding: '12px 24px',
                alignItems: 'center',
                gap: '12px',
                flex: '1 0 0',
              }}
              variant="contained">
              Reenviar link
            </Button>
            <Typography
              sx={{
                marginTop: '24px',
                color: '#27272A',
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '16px',
              }}>
              ¿No lo recibiste?
            </Typography>
            <Typography
              sx={{
                marginTop: '14px',
                textAlign: 'center',
                color: '#A6A6B0',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '16px',
                textDecorationStyle: 'solid',
                textDecorationSkipInk: 'none',
                textDecorationThickness: 'auto',
                textUnderlineOffset: 'auto',
                textUnderlinePosition: 'from-font',
                '& a': {
                  textDecoration: 'underline',
                  color: '#A6A6B0',
                },
              }}>
              <a href="https://wa.me/56931107304">Contacta al soporte técnico</a>
            </Typography>
          </Box>
        </Fragment>
      </SwipeableViews>
    </Box>
  );
};

export default RecoverPassword;
